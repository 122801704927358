import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormDirective } from '../directives/base-form.directive';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { TsAutocompleteComponent } from '../ts-autocomplete';

@Component({
  selector: 'ts-input',
  templateUrl: './ts-input.component.html',
  styleUrls: ['./ts-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TsInputComponent,
      multi: true,
    },
  ],
})
export class TsInputComponent extends BaseFormDirective implements AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() autofocus = false;
  @Input() type = 'text';
  @Input() tabindex = 0;
  @Input() dataCy: string;
  @Input() errorMessages: { [validationErrorKey: string]: string };
  @Input() readonly = false;
  @Input() roundedBorders = true;
  @Input() variant: 'standard' | 'slim' = 'standard';
  @Input() size: 'md' | 'lg' = 'md';
  @Input() autocomplete = 'off';

  @Output() blur = new EventEmitter<void>();

  @ContentChild(TsAutocompleteComponent) autoCompleteComponentRef: TsAutocompleteComponent;
  matAutocompleteComponentRef: MatAutocomplete;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngAfterViewInit(): void {
    if (this.autoCompleteComponentRef) {
      this.matAutocompleteComponentRef = this.autoCompleteComponentRef.autocomplete;
    }
  }
}
