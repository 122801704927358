import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { environment } from '@env/environment';
import { User } from 'app/core/models/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { EthanChat, EthanMessage } from '@core/models/ethan.types';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EthanState } from '../../../../state/ethan/ethan.state';
import {
  GetEthanChats,
  AskEthan,
  CreateEthanChat,
  DeleteEthanChat,
  GetEthanQuestions,
  UpdateEthanAnswer,
} from '../../../../state/ethan/ethan.action';

import { EthanService } from '../../../../state/ethan/ethan.service';

@Component({
  selector: 'app-floating-ethan',
  templateUrl: './floating-ethan.component.html',
  styleUrls: ['./floating-ethan.component.scss'],
})
export class FloatingEthanComponent extends OnDestroyMixin implements OnInit {
  comment: Comment = {} as Comment;
  comments: Comment[] = [];
  public messages: any[] = [];
  public isLoading: boolean = false;
  public ethanQuestions: EthanMessage[] = [];
  public recentQuestion: any = null;
  public ethanForm: FormGroup;
  public reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  public streamData: string = '';
  public currentUser: User;
  public chat_selected: EthanChat | null = null;
  public ethanChats: EthanChat[] = [];
  public searchControl = new FormControl();
  public newChatControl = new FormControl();
  public createNewInputVisible: boolean = false;
  public chatsLoading: boolean = true;
  public messagesLoading: boolean = false;
  public isFullScreen: boolean = false;

  @ViewChild('ethanChatContainer') ethanChatContainer!: ElementRef;
  @ViewChild('newChatInput') newChatInput!: ElementRef;
  @ViewChild('chatContent') chatContent!: ElementRef;

  @Select(EthanState.ethanChats) ethanChats$: Observable<EthanChat[]>;
  @Select(EthanState.ethanQuestions) ethanQuestions$: Observable<EthanMessage[]>;

  constructor(
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly ethanService: EthanService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly matDialogRef: MatDialogRef<FloatingEthanComponent>,
  ) {
    super();
    this.ethanForm = this.formBuilder.group({
      question: [''],
    });
  }

  ngOnInit() {
    this.store.dispatch(new GetEthanChats());

    this.ethanChats$.pipe(untilComponentDestroyed(this)).subscribe((ethanChats) => {
      this.ethanChats = ethanChats;
      this.chatsLoading = false;
      this.changeDetectorRef.markForCheck();
    });

    this.ethanQuestions$.pipe(untilComponentDestroyed(this)).subscribe((ethanQuestions) => {
      this.ethanQuestions = ethanQuestions;
      this.messagesLoading = false;
      this.changeDetectorRef.markForCheck();
    });

    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchText) => this.store.dispatch(new GetEthanChats(searchText))),
        untilComponentDestroyed(this),
      )
      .subscribe(() => {
        this.createNewInputVisible = false;
        this.chatsLoading = false;
        this.changeDetectorRef.markForCheck();
      });

    this.userService.user$.pipe(untilComponentDestroyed(this)).subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  public closeEthan(): void {
    this.matDialogRef.close();
  }

  public openFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      this.matDialogRef.updateSize('calc(100% - 40px)', 'calc(100% - 125px)');
    } else {
      this.matDialogRef.updateSize('400px', '550px');
      this.matDialogRef.updatePosition({ bottom: '104px', left: '24px' });
    }
  }

  public askQuestionToEthan(): void {
    let question = this.ethanForm.get('question')?.value;
    this.ethanForm.get('question').setValue('');

    if (question.trim()) {
      this.isLoading = true;

      this.store
        .dispatch(new AskEthan(question, this.chat_selected?.id))
        .pipe(
          untilComponentDestroyed(this),
          switchMap(() => {
            this.scrollChatToBottom();
            return this.ethanService.ethanPostStream(
              environment.chatAssistant.url,
              { input: question },
              this.currentUser,
              this.chat_selected?.id,
            );
          }),
        )
        .subscribe(
          async (stream) => {
            this.scrollChatToBottom();
            this.reader = stream.getReader();
            const decoder = new TextDecoder();

            const readChunk = async () => {
              while (this.reader && this.isLoading) {
                try {
                  const { done, value } = await this.reader.read();
                  if (done) {
                    this.reader = null;
                    break;
                  }
                  const chunk = decoder.decode(value, { stream: true });
                  this.streamData += chunk;
                  this.ethanQuestions[this.ethanQuestions.length - 1].answer = this.streamData;
                } catch (error) {
                  console.error('Stream error:', error);
                  this.reader = null;
                  this.isLoading = false;
                  break;
                }
              }
              this.isLoading = false;
              this.store.dispatch(
                new UpdateEthanAnswer(
                  this.streamData,
                  this.chat_selected?.id,
                  this.ethanQuestions[this.ethanQuestions.length - 1]?.id,
                ),
              );
              this.streamData = '';
            };

            readChunk();
          },
          (error) => {
            console.error('Error:', error);
          },
        );
    }
  }

  public stopReading() {
    this.isLoading = false;
    if (this.reader) {
      this.reader.cancel();
      this.reader = null;
    }
  }

  public toggleScreen(chat_id: number): void {
    this.messagesLoading = true;
    this.chat_selected = this.ethanChats.find((ec) => ec.id === chat_id);
    this.createNewInputVisible = false;
    this.store
      .dispatch(new GetEthanQuestions(chat_id))
      .pipe(untilComponentDestroyed(this))
      .subscribe((res) => {
        this.messagesLoading = false;
        this.scrollChatToBottom();
      });
  }

  public onBack(): void {
    this.chat_selected = null;
    this.ethanQuestions = [];
  }

  public createNewChat() {
    const chatName = this.newChatControl.value.trim();
    this.store
      .dispatch(new CreateEthanChat(chatName))
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.createNewInputVisible = false;
        this.searchControl.reset();
      });
  }

  public onCreateNewClick() {
    const chatName = this.newChatControl?.value?.trim();
    if (this.createNewInputVisible && chatName) {
      this.createNewChat();
    } else {
      this.createNewInputVisible = true;
      setTimeout(() => {
        if (this.ethanChatContainer) {
          const element = this.ethanChatContainer.nativeElement;
          element.scrollTop = element.scrollHeight;
        }
      }, 0);
      setTimeout(() => {
        if (this.newChatInput) {
          this.newChatInput.nativeElement.focus();
        }
      }, 0);
    }
  }

  public hideInput() {
    this.createNewInputVisible = false;
  }

  public onDelete(chatId: number) {
    this.store.dispatch(new DeleteEthanChat(chatId)).subscribe(() => {
      this.ethanChats = this.ethanChats.filter((chat) => chat.id !== chatId);
      this.chat_selected = null;
    });
  }
  private scrollChatToBottom(): void {
    try {
      if (this.chatContent) {
        const element = this.chatContent.nativeElement;
        element.scrollTop = element.scrollHeight;
      }
    } catch (err) {
      console.log(err);
    }
  }
}
