import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ColumnDef } from '../ts-table/ts-table.model';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import formatMoney from 'accounting-js/lib/formatMoney.js';
import moment from 'moment';
import { TableActionsRendererComponent } from '../ts-table/renderer-components/table-actions-renderer.component';
import { TsAgMultiSelectRenderer } from '../ts-table/renderer-components/table-select-renderer.component';
import { TsAgMultiSelectEditor } from '../ts-table/renderer-components/table-select-editor.component';
import { TableTagsRendererComponent } from '../ts-table/renderer-components/table-tags-renderer.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root',
})
export class TsTableService {
  convertFieldDefsToColDefs(fieldDefs: ColumnDef[], relatedToRedirectConfig: any, data?: any): ColDef[] {
    return fieldDefs.map((fieldDef) => {
      const colDef: ColDef = {
        ...this.setFieldSettings(fieldDef, relatedToRedirectConfig, data),
      };
      return colDef;
    });
  }

  setFieldSettings(fieldDef: ColumnDef, relatedToRedirectConfig: any, data?: any): ColDef {
    const isNestedField = fieldDef.name.includes('.');
    const colDef: ColDef = {
      headerName: fieldDef.tsTableOptions?.headerName,
      field: fieldDef.tsTableOptions?.field,
      sortable: isNestedField ? false : fieldDef.tsTableOptions?.sortable,
      filter: false,
      cellDataType: fieldDef.type,
      editable: isNestedField ? false : !fieldDef.meta?.readonly,
      resizable: fieldDef.tsTableOptions?.resizable,
      checkboxSelection: fieldDef.tsTableOptions?.checkboxSelection,
      headerCheckboxSelection: fieldDef.tsTableOptions?.headerCheckboxSelection,
      suppressMovable: fieldDef.tsTableOptions?.suppressMovable,
      lockPosition: fieldDef.tsTableOptions?.lockPosition,
      lockPinned: fieldDef.tsTableOptions?.lockPinned,
      width: fieldDef.tsTableOptions?.width,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      autoHeaderHeight: true,
      cellStyle: fieldDef.tsTableOptions?.cellStyle,
      autoHeight: fieldDef.tsTableOptions?.autoHeight,
      pinned: fieldDef.tsTableOptions?.pinned,
      ...this.setCellEditor(fieldDef, relatedToRedirectConfig, data),
      valueFormatter: this.setValueFormatter(fieldDef),
      ...this.setCellRenderer(fieldDef, relatedToRedirectConfig),
    };
    return colDef;
  }

  setValueFormatter(fieldDef: ColumnDef): (params: any) => string {
    switch (fieldDef.type) {
      case 'date':
      case 'datetime':
        return (params) => {
          const value: any = this.getValueFromParam(fieldDef, params);

          return value ? moment(value).format('MM/DD/YYYY') : '';
        };
      case 'number':
        return (params) => {
          let value: any = this.getValueFromParam(fieldDef, params);

          if (!fieldDef.meta?.scale) {
            return value;
          }

          value = value ? parseFloat(value) : null;

          const formattedValue = value !== null ? formatNumber(value, { precision: fieldDef.meta?.scale || 0 }) : '';

          return formattedValue;
        };
      case 'money':
        return (params) => {
          let value: any = this.getValueFromParam(fieldDef, params);

          value = value ? parseFloat(value) : null;

          const formattedValue = value !== null ? formatMoney(value) : '';

          return formattedValue;
        };
      case 'percent':
        return (params) => {
          const value: any = this.getValueFromParam(fieldDef, params);

          const formattedValue = value ? `${value}%` : '';

          return formattedValue;
        };
      case 'select':
        return (params) => {
          const selectedOption = params.colDef.cellEditorParams?.options?.find((option) => option.id == params.value);
          return selectedOption ? selectedOption.name : params.value;
        };
      default:
        return (params) => null;
    }
  }

  setCellEditor(fieldDef: ColumnDef, relatedToRedirectConfig: any, data?: any): Partial<ColDef> {
    switch (fieldDef.type) {
      case 'number':
        return {
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: fieldDef.meta?.scale || 0,
            showStepperButtons: false,
          },
        };
      case 'money':
        return {
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: fieldDef.meta?.scale || 0,
            showStepperButtons: false,
          },
        };
      case 'year':
        return {
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 0,
            showStepperButtons: false,
            min: 1900,
            max: 9999,
          },
        };
      case 'date':
      case 'datetime':
        return {
          cellEditor: 'agDateStringCellEditor',
          cellDataType: 'dateString',
        };
      case 'checkbox':
        return {
          cellEditor: 'agCheckboxCellEditor',
        };
      case 'textarea':
        return {
          cellEditor: 'agLargeTextCellEditor',
        };
      case 'url':
        return {
          cellEditor: 'agTextCellEditor',
        };
      case 'select':
        let options = fieldDef.meta?.options;
        if (options?.length > 0) {
          options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
        } else {
          options = data?.options[fieldDef.meta.option_name]
            ?.filter((option) => !option.hidden)
            .map((option) => ({ id: option.id, name: option.name }));
        }

        return {
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: {
            values: options?.map((option) => option.id) || [],
            options: options,
            allowTyping: true,
            filterList: true,
            multiSelect: fieldDef.meta?.multi_select,
            highlightMatch: true,
            valueListMaxHeight: 220,
            formatValue: (value) => {
              const selectedOption = options?.find((option) => option.id === value);
              return selectedOption ? selectedOption.name : value;
            },
          },
        };
      case 'record':
        return {
          cellEditor: TsAgMultiSelectEditor,
          cellRenderer: TsAgMultiSelectRenderer,
          cellEditorParams: {
            multiSelect: fieldDef.meta?.multi_select,
            recordType: fieldDef.meta?.record_type,
            options: fieldDef.meta?.options,
          },
          cellRendererParams: {
            relatedToRedirectConfig,
            recordType: fieldDef.meta?.record_type,
          },
        };
      default:
        return {
          cellEditor: 'agTextCellEditor',
        };
    }
  }

  setCellRenderer(fieldDef: ColumnDef, relatedToRedirectConfig: any): ColDef {
    if (fieldDef.tsTableOptions?.cellRenderer) {
      return {
        cellRenderer: fieldDef.tsTableOptions.cellRenderer,
        cellRendererParams: { ...fieldDef.tsTableOptions.cellRendererParams, relatedToRedirectConfig },
      };
    }

    switch (fieldDef.type) {
      case 'tags':
        return {
          cellRenderer: TableTagsRendererComponent,
        };
      case 'checkbox':
        return {
          cellRenderer: 'agCheckboxCellRenderer',
          valueGetter: (params) => {
            return params.data[params.colDef.field] === 'true';
          },
        };
      case 'url':
        return {
          cellRenderer: (params) => {
            const url = params.value;
            return url ? `<a href="${url}" target="_blank" style="text-decoration: underline;">${url}</a>` : '';
          },
        };
      default:
        return;
    }
  }

  setActionsColumn(params): ColDef {
    return {
      headerName: 'Actions',
      field: 'actions',
      pinned: 'right',
      lockPinned: true,
      lockPosition: 'right',
      resizable: false,
      suppressMovable: true,
      cellRenderer: TableActionsRendererComponent,
      cellRendererParams: params,
    };
  }

  public static moveColumns(
    event: any,
    displayColumns: string[],
    excludedFields: string[], // cols that we ignore (like 'openDetails', 'checkbox')
    updateColumnsCallback: (columns: string[]) => void, // cb function to update the columns
  ): void {
    if (event.toIndex !== undefined && event.finished) {
      const movedColumn = event.column?.colDef?.field;

      if (movedColumn && !excludedFields.includes(movedColumn)) {
        const previousIndex = displayColumns.indexOf(movedColumn);
        const currentIndex = event.toIndex - excludedFields.length; // index adjustment based on the columns that we're going to ignore

        if (previousIndex !== currentIndex && previousIndex > -1) {
          moveItemInArray(displayColumns, previousIndex, currentIndex);
          updateColumnsCallback(displayColumns);
        }
      }
    }
  }

  private getValueFromParam(columnDef: ColumnDef, params: any): any {
    let value: any;

    if (columnDef.name.indexOf('.') > -1) {
      const fieldName = columnDef.name.split('.').pop();
      value = params.value?.[fieldName];
    } else {
      value = params.value;
    }

    return value;
  }
}
