<main class="bg-white rounded">
  @if (!suppressHeader) {
    <header class="p-5 flex flex-row items-center justify-between">
      <p class="ext-ts-header-title font-ts-header-title">{{ title }}</p>
      <button class="ts-dialog-close flex flex-row items-center" (click)="closeDialog.emit()">
        <ts-icon color="black" icon="close"></ts-icon>
      </button>
    </header>
  }
  <ts-divider spacing="none" />
  <section class="p-5">
    <ng-content select="[ts-dialog-body]"></ng-content>
  </section>
  <ts-divider spacing="none" />
  <footer class="p-5 flex flex-row justify-end">
    <ng-content select="[ts-dialog-actions]"></ng-content>
  </footer>
</main>
