import { mergeMap, Observable, of, throwError, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export abstract class TsRxjsOperators {
  static memoizedDebounceTime(time: number) {
    return <T = any>(source: Observable<T>) => {
      return source.pipe(debounceTime(time), distinctUntilChanged());
    };
  }

  /**
   * It's provides a generic retry strategy for http requests,
   * Usage: retryWhen(CustomRxjsOperators.genericRetryStrategy())
   *
   * @param maxRetryAttempts
   * @param scalingDuration
   * @param excludedStatusCodes
   */

  static genericRetryStrategy({
    maxRetryAttempts = 2,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) {
    return (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
            return throwError(error);
          }

          // retry after 1s, 2s, etc...
          return timer(retryAttempt * scalingDuration);
        }),
      );
    };
  }

  static handle403toEmptyArray() {
    return <T = any>(source: Observable<T>) => {
      return source.pipe(
        catchError((error) => {
          if (error.status === 403) {
            return of([]);
          }
          return throwError(() => error);
        }),
      );
    };
  }
}
